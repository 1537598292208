<template>
  <div
    class="acordeon-content"
    :style="
      showData ? 'background-color: #FBEEE3;' : 'background-color: white;'
    "
  >
    <div class="header-acordeon-content" @click="showData = !showData">
      <p :style="showData ? 'font-weight: bold' : ''">
        {{ dataQustion.titulo }}
      </p>
      <img v-if="showData" src="@/assets/icons/arrow-up.svg" alt="arrow up" />
      <img v-else src="@/assets/icons/arrow-down.svg" alt="arrow down" />
    </div>
    <div class="content-acordeon-data" :class="showData ? 'd-block' : 'd-none'">
      <p v-html="dataQustion.descripcion"></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataQustion: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      showData: false,
    }
  },
}
</script>
