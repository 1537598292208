<template>
  <div class="frecuent-questions">
    <header-page-derek
      :title="'Preguntas frecuentes'"
      :description="''"
      :skeleton="false"
    />
    <div class="container-frecuent-questions container-max-width">
      <div class="col-12 col-md-5 content-summary-frecuent-questions">
        <h2>Explora las dudas más frecuentes</h2>
        <p>
          En este espacio podrás resolver cualquier duda que tengas sobre
          nuestros procesos, productos y compras en nuestra tienda Online, Derek
          Colombia.
        </p>
        <p class="more-space-word">
          <a href="https://www.facebook.com/derekaddict" target="_blank"
            >Facebook / </a
          ><br />
          <a href="https://www.instagram.com/derekaddict/" target="_blank"
            >Instagram /
          </a>
          <br />
          <a href="https://twitter.com/DerekAddict" target="_blank"
            >Twitter / </a
          ><br />
        </p>
        <p class="more-space-word">
          WhatsApp:
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=573232747474&text=Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto"
            >323 274 7474 </a
          ><br />
          Línea Nacional: <a href="tel:018000423550">01 8000 423550 </a><br />
          <a href="mailto:servicioalcliente@derek.co"
            >servicioalcliente@derek.co </a
          ><br />
        </p>
      </div>
      <div
        v-if="dataFrecuentQuestions"
        class="col-12 col-md-7 content-info-frecuent-questions"
      >
        <card-frecuent-questions
          v-for="(data, index) in dataFrecuentQuestions.items"
          :key="index"
          :data-qustion="data"
        />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'
import CardFrecuentQuestions from '@/components/FrecuentQuestions/CardFrecuentQuestions'
export default {
  components: { HeaderPageDerek, CardFrecuentQuestions },
  data() {
    return {
      dataFrecuentQuestions: null,
    }
  },
  mounted() {
    document.title = 'Preguntas frecuentes | DEREK Tienda Online'
    this.getPolitics()
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    async getPolitics() {
      await this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/politicas',
      })
        .then(response => {
          this.dataFrecuentQuestions = response.data[6]
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
